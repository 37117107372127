@import url(sanitize.css);

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  min-width: 1366px;
}

html, body, input, button, textarea {
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

img {
  max-width: 100%;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  line-height: normal;
  text-align: inherit;
  background-color: transparent;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:active {
  outline: none;
}

p {
  margin: 0;
  padding: 0;
}

a {
  display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
